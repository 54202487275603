import "babel-polyfill";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import {BrowserRouter} from 'react-router-dom';
import { OidcProvider } from "redux-oidc";
import { PersistGate } from 'redux-persist/integration/react';

import mainStarterInstance from "./utils/applicationStarter";

import NolischeLoader from './utils/nolischeLoader';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

export function startFunction(userManager, history, storeData, MainContainer)
{
    ReactDOM.render(
        <Provider store={storeData.store}>
            <PersistGate loading={<NolischeLoader />} persistor={storeData.persistor}>
                <OidcProvider store={storeData.store} userManager={userManager}>
                    <BrowserRouter basename="/#/">
                        <Suspense fallback={<NolischeLoader />}>
                            <MainContainer />
                        </Suspense>
                    </BrowserRouter>
                </OidcProvider>
            </PersistGate>
        </Provider>
    , document.getElementById('root'));

    if (process.env.NODE_ENV === 'production') 
    {
        const registerSW = async () => {
            const registration = await navigator.serviceWorker.register('/app_bleiben/services/gemeinsam_cms_api/sw.js', { scope: "/" });
            return registration;
        }
        
        (async () => {
            if(('serviceWorker' in navigator))
            {
                await registerSW()
            }
        })();
    }
}

mainStarterInstance.pullConfigAndStartAppAsync(startFunction);


